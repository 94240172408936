<template>
  <v-row class="d-flex justify-end">
    <v-col cols="12" sm="5">
      <v-text-field
        dense
        hide-details
        outlined
        class="caption"
        ref="search"
        @keyup.enter="handleSearch"
        placeholder="Cari No Jurnal"
        v-model="search"
      >
        <template v-slot:prepend-inner>
          <v-icon @click="handleSearch">mdi-magnify </v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'filter-list',
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search: this.$route.query.journalNumber || '',
    };
  },
  methods: {
    handleSearch() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          journalNumber: this.search,
        },
      });
      this.filter.journalNumber = this.search;
      this.$emit('refetch');
    },
  },
};

</script>

<template>
  <v-container fluid>
    <v-sheet rounded class="pa-4">
      <v-row>
        <v-col cols="12">
          <FilterList
            :filter="filter"
            @refetch="refetch"
          />
        </v-col>
        <v-col cols="12">
          <TableList
            :filter="filter"
            :key="keyList"
            ref="tableList"
          />
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import TableList from './TableList.vue';
import FilterList from './FilterList.vue';

export default {
  name: 'list-accrual-oracle',
  components: {
    TableList,
    FilterList,
  },
  data() {
    return {
      keyList: 0,
      filter: {
        journalNumber: this.$route.query.journalNumber || '',
      },
    };
  },
  methods: {
    refetch() {
      this.keyList += 1;
    },
  },
};
</script>
